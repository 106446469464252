import React, { useEffect } from "react"

export const withPageDetails = (Component, { pageTitle }) => {
    return (props) => {
        useEffect(() => {
            document.title = pageTitle
            window.scrollTo(0, 0)
        }, [])
        return <Component {...props} />
    }
}
