import { useLocation } from 'react-router'
import { getItem} from "../../../../lib/myStore"
export const useActiveLinkSet = () => {
    const { pathname } = useLocation()
    const userInfo = getItem("userInfo")
    let activeLinkSet = 'linkset-1'
    let array = pathname.split('/');
    if (!userInfo.selectedTenantID && (array.includes('ffiec') && array.includes('compliance'))) {
        activeLinkSet = 'linkset-2'
    }
    if (!userInfo.selectedTenantID && (array.includes('finra') && array.includes('compliance'))) {
        activeLinkSet = 'linkset-3'
    }
    // if (pathname.includes('/cis')) {
    //     activeLinkSet = 'linkset-3'
    // }
    if ((pathname.includes('/mspDashboard') || pathname.includes('/mspAdmin'))) {
        activeLinkSet = 'linkset-4'
    }
    if (userInfo.selectedTenantID && (array.includes('ffiec') && array.includes('compliance'))) {
      console.log('66666666');
        activeLinkSet = 'linkset-6'
    }
    if (userInfo.selectedTenantID && (array.includes('finra') && array.includes('compliance'))) {
        activeLinkSet = 'linkset-7'
    }
    console.log(activeLinkSet,'---------');
    return {
        activeLinkSet
    }
}
