import React, {Suspense, useEffect } from "react"
import { Switch, Route } from "react-router-dom"
import { matchPath } from "react-router"

import { BrowserView, MobileView, isBrowser, isMobile, deviceDetect, browserName } from "react-device-detect"

import { ContentWrapper, ThemeProvider } from "fictoan-react"

import { GlobalsStyle } from "../styles/Globals.styled"
import { removeAll, getItem } from "../lib/myStore"


// import { Login } from "../pages/Gateway/Login/Login"
// import { VendorLogin } from "../pages/Gateway/VendorLogin/VendorLogin"
// import { ForgotPassword } from "../pages/Gateway/ForgotPassword/ForgotPassword"
// import { SetNewPassword } from "../pages/Gateway/SetNewPassword/SetNewPassword"
// import { SetPassword } from "../pages/Gateway/SetPassword/SetPassword"

// import { QuickEvaluationQuestionnaire } from "../pages/Gateway/QuickEvaluation/Questionnaire"
// import { QuickEvaluationResults } from "../pages/Gateway/QuickEvaluation/Results"

import { TDTheme } from "../styles/Theme"
// import { VendorIRQ } from "../pages/Gateway/VendorIRQ/Questionnaire"

//  CORE  /////////////////////////////////////////////////////////////////////
// import { Home } from "../pages/Home/Home"

//  IRQ  //////////////////////////////////////////////////////////////////////
// import { IRQ } from "../pages/Risk-Evaluate-IRQ/IRQ"
// import { IRQReport } from "../pages/Risk-Evaluate-IRQ/DetailedReport"

// //  CYBER INSURANCE IRQ  //////////////////////////////////////////////////////////////////////
// import { CIIRQ } from "../pages/Cyber-Insurance-IRQ/IRQ"
// import { CIIRQReport } from "../pages/Cyber-Insurance-IRQ/DetailedReport"

// //  SAQ  //////////////////////////////////////////////////////////////////////
// import { SAQ } from "../pages/Risk-Assess-SAQ/SAQ"
// import { SAQRisks } from "../pages/Risk-Assess-SAQ/Risks"


// //  VENDOR - SAQ  //////////////////////////////////////////////////////////////////////
// import { VendorSAQ } from "../pages/Vendors/SAQ"

// //  ADMIN  ////////////////////////////////////////////////////////////////////
// import { Users } from "../pages/Admin/Users/Users"

// import { Error404 } from "../pages/404/404"
// import { VendorHome } from "../pages/Vendors/VendorsDashboard/VendorsDashboard"
// import { VendorProfile } from "../pages/Vendors/VendorProfile/VendorProfile"
import { Sidebar } from "../assets/components/Global/Sidebar/Sidebar"
// import { Footer } from "../assets/components/Global/Footer/Footer"

// import { MitigateHome } from "../pages/Risk-Mitigate/Actions"
// import { MitigateActionPage } from "../pages/Risk-Mitigate/ActionDetails"
// import { MitigateActionNewPage } from "../pages/Risk-Mitigate/ActionNew"
// import { MitigateActionPageAccepted } from "../pages/Risk-Mitigate/ActionDetailsAccepted"
// import { MitigateActionPageCancelled } from "../pages/Risk-Mitigate/ActionCancelled"
// import { MitigateDash2 } from "../pages/Risk-Mitigate/MitigateDash2"
// import { MitigateDash } from "../pages/Risk-Mitigate/MitigateDash"
// import { MitigateDashEmpty } from "../pages/Risk-Mitigate/MitigateDashEmpty"
// import { Heatmap } from "../pages/Risk-Assess-SAQ/Heatmap"
// import { SignUp } from "../pages/Gateway/SignUp/SignUp"
// import { OrgProfile } from "../pages/Admin/KYC/OrgProfile"
// import { Invite } from "../pages/Gateway/Invite/Invite"
// import { SAQThreats } from "../pages/Risk-Assess-SAQ/Threats"
// import { ComingSoon } from "../pages/ErrorPages/ComingSoon"
// import { UnsupportedBrowser } from "../pages/ErrorPages/UnsupportedBrowser"
// import { UnknownError } from "../pages/ErrorPages/Unknown"
// import { Maintenance } from "../pages/ErrorPages/Maintenance"
// import { Profile } from "../pages/Admin/Profile/Profile"
// import { FfiecHome } from "../pages/Compliance/FFIEC/Home"
// import { FfiecIRQ } from "../pages/Compliance/FFIEC/IRQ/index"
// import { FfiecIRQReport } from "../pages/Compliance/FFIEC/IRQ/report"
// import { FFiecCyberMaturity } from "../pages/Compliance/FFIEC/Cyber-Maturity"
// import { FfiecMaturityReport } from "../pages/Compliance/FFIEC/Cyber-Maturity/report"
// import { FfiecDataMetrics } from "../pages/Compliance/FFIEC/Cyber-Maturity/data-metrics"
// import { FFIECActions } from "../pages/Compliance/FFIEC/POA/"
// // import { FfiecActionDetails } from "../pages/Compliance/FFIEC/POA/action"
// import { FfiecActionNewPage } from "../pages/Compliance/FFIEC/POA/ActionNew"
// import { FfiecActionDetailsNew } from "../pages/Compliance/FFIEC/POA/ActionDetails"
// import {FfiecActionAccepted} from "../pages/Compliance/FFIEC/POA/ActionDetailsAccepted"
// import {FfiecActionCancelled} from "../pages/Compliance/FFIEC/POA/ActionCancelled"
// import {FfiecPoaDash} from "../pages/Compliance/FFIEC/POA/FfiecPoaDash"
// import { TermsAndConditions } from "../assets/components/Global/TermsAndConditions/TermsAndConditions.jsx"
// import { PrivacyPolicy } from "../assets/components/Global/PrivacyPolicy/PrivacyPolicy.jsx"

// const GlobalsStyle = React.lazy(() =>
//   import('../styles/Globals.styled').then(module => ({ default: module.GlobalsStyle }))
// );

const Login = React.lazy(() =>
  import('../pages/Gateway/Login/Login').then(module => ({ default: module.Login }))
);
const VendorLogin = React.lazy(() =>
    import('../pages/Gateway/VendorLogin/VendorLogin').then(module => ({ default: module.VendorLogin }))
);
const ForgotPassword = React.lazy(() =>
    import('../pages/Gateway/ForgotPassword/ForgotPassword').then(module => ({ default: module.ForgotPassword }))
);
const SetNewPassword = React.lazy(() =>
    import('../pages/Gateway/SetNewPassword/SetNewPassword').then(module => ({ default: module.SetNewPassword }))
);
const SetPassword = React.lazy(() =>
    import('../pages/Gateway/SetPassword/SetPassword').then(module => ({ default: module.SetPassword }))
);
const QuickEvaluationQuestionnaire = React.lazy(() =>
  import('../pages/Gateway/QuickEvaluation/Questionnaire').then(module => ({ default: module.QuickEvaluationQuestionnaire }))
);
const QuickEvaluationResults = React.lazy(() =>
  import('../pages/Gateway/QuickEvaluation/Results').then(module => ({ default: module.QuickEvaluationResults }))
);
// const TDTheme = React.lazy(() =>
//   import('../styles/Theme').then(module => ({ default: module.TDTheme }))
// );
const VendorIRQ = React.lazy(() =>
  import('../pages/Gateway/VendorIRQ/Questionnaire').then(module => ({ default: module.VendorIRQ }))
);

const Home = React.lazy(() =>
  import('../pages/Home/Home').then(module => ({ default: module.Home }))
);

const IRQ = React.lazy(() =>
  import('../pages/Risk-Evaluate-IRQ/IRQ').then(module => ({ default: module.IRQ }))
);
const IRQReport = React.lazy(() =>
  import('../pages/Risk-Evaluate-IRQ/DetailedReport').then(module => ({ default: module.IRQReport }))
);
const CIIRQ = React.lazy(() =>
  import('../pages/Cyber-Insurance-IRQ/IRQ').then(module => ({ default: module.CIIRQ }))
);
const CIIRQReport = React.lazy(() =>
  import('../pages/Cyber-Insurance-IRQ/DetailedReport').then(module => ({ default: module.CIIRQReport }))
);
const SAQ = React.lazy(() =>
  import('../pages/Risk-Assess-SAQ/SAQ').then(module => ({ default: module.SAQ }))
);
const SAQRisks = React.lazy(() =>
  import('../pages/Risk-Assess-SAQ/Risks').then(module => ({ default: module.SAQRisks }))
);
const VendorSAQ = React.lazy(() =>
  import('../pages/Vendors/SAQ').then(module => ({ default: module.VendorSAQ }))
);
const Users = React.lazy(() =>
  import('../pages/Admin/Users/Users').then(module => ({ default: module.Users }))
);
const Error404 = React.lazy(() =>
  import('../pages/404/404').then(module => ({ default: module.Error404 }))
);
const MFAErrorPage = React.lazy(() =>
  import('../pages/MFAErrorPage/MFAErrorPage').then(module => ({ default: module.MFAErrorPage }))
);
const VendorHome = React.lazy(() =>
  import('../pages/Vendors/VendorsDashboard/VendorsDashboard').then(module => ({ default: module.VendorHome }))
);
const VendorProfile = React.lazy(() =>
  import('../pages/Vendors/VendorProfile/VendorProfile').then(module => ({ default: module.VendorProfile }))
);
// const Sidebar = React.lazy(() =>
//   import('../assets/components/Global/Sidebar/Sidebar').then(module => ({ default: module.Sidebar }))
// );
const Footer = React.lazy(() =>
  import('../assets/components/Global/Footer/Footer').then(module => ({ default: module.Footer }))
);
const MitigateHome = React.lazy(() =>
  import('../pages/Risk-Mitigate/Actions').then(module => ({ default: module.MitigateHome }))
);
const MitigateActionNewPage = React.lazy(() =>
  import('../pages/Risk-Mitigate/ActionNew').then(module => ({ default: module.MitigateActionNewPage }))
);
const MitigateActionPage = React.lazy(() =>
  import('../pages/Risk-Mitigate/ActionDetails').then(module => ({ default: module.MitigateActionPage }))
);
const MitigateActionPageAccepted = React.lazy(() =>
  import('../pages/Risk-Mitigate/ActionDetailsAccepted').then(module => ({ default: module.MitigateActionPageAccepted }))
);
const MitigateActionPageNotPlanned = React.lazy(() =>
  import('../pages/Risk-Mitigate/ActionDetailsNotPlanned').then(module => ({ default: module.MitigateActionPageNotPlanned }))
);
const MitigateActionPageCancelled = React.lazy(() =>
  import('../pages/Risk-Mitigate/ActionCancelled').then(module => ({ default: module.MitigateActionPageCancelled }))
);
const MitigateDash2 = React.lazy(() =>
  import('../pages/Risk-Mitigate/MitigateDash2').then(module => ({ default: module.MitigateDash2 }))
);
const MitigateDash = React.lazy(() =>
  import('../pages/Risk-Mitigate/MitigateDash').then(module => ({ default: module.MitigateDash }))
);
const MitigateDashEmpty = React.lazy(() =>
  import('../pages/Risk-Mitigate/MitigateDashEmpty').then(module => ({ default: module.MitigateDashEmpty }))
);
const Heatmap = React.lazy(() =>
  import('../pages/Risk-Assess-SAQ/Heatmap').then(module => ({ default: module.Heatmap }))
);
const SignUp = React.lazy(() =>
  import('../pages/Gateway/SignUp/SignUp').then(module => ({ default: module.SignUp }))
);

const OrgProfile = React.lazy(() =>
  import('../pages/Admin/KYC/OrgProfile').then(module => ({ default: module.OrgProfile }))
);
const Invite = React.lazy(() =>
  import('../pages/Gateway/Invite/Invite').then(module => ({ default: module.Invite }))
);
const SAQThreats = React.lazy(() =>
  import('../pages/Risk-Assess-SAQ/Threats').then(module => ({ default: module.SAQThreats }))
);
const ComingSoon = React.lazy(() =>
  import('../pages/ErrorPages/ComingSoon').then(module => ({ default: module.ComingSoon }))
);
const UnsupportedBrowser = React.lazy(() =>
  import('../pages/ErrorPages/UnsupportedBrowser').then(module => ({ default: module.UnsupportedBrowser }))
);
const UnknownError = React.lazy(() =>
  import('../pages/ErrorPages/Unknown').then(module => ({ default: module.UnknownError }))
);
const Maintenance = React.lazy(() =>
  import('../pages/ErrorPages/Maintenance').then(module => ({ default: module.Maintenance }))
);
const Profile = React.lazy(() =>
  import('../pages/Admin/Profile/Profile').then(module => ({ default: module.Profile }))
);
const FfiecHome = React.lazy(() =>
  import('../pages/Compliance/FFIEC/Home').then(module => ({ default: module.FfiecHome }))
);
const FfiecIRQ = React.lazy(() =>
  import('../pages/Compliance/FFIEC/IRQ/index').then(module => ({ default: module.FfiecIRQ }))
);
const FfiecIRQReport = React.lazy(() =>
  import('../pages/Compliance/FFIEC/IRQ/report').then(module => ({ default: module.FfiecIRQReport }))
);
const FFiecCyberMaturity = React.lazy(() =>
  import('../pages/Compliance/FFIEC/Cyber-Maturity').then(module => ({ default: module.FFiecCyberMaturity }))
);
const FfiecMaturityReport = React.lazy(() =>
  import('../pages/Compliance/FFIEC/Cyber-Maturity/report').then(module => ({ default: module.FfiecMaturityReport }))
);
const FfiecDataMetrics = React.lazy(() =>
  import('../pages/Compliance/FFIEC/Cyber-Maturity/data-metrics').then(module => ({ default: module.FfiecDataMetrics }))
);
const FFIECActions = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/').then(module => ({ default: module.FFIECActions }))
);
const FfiecActionNewPage = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/ActionNew').then(module => ({ default: module.FfiecActionNewPage }))
);
const FfiecActionDetailsNew = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/ActionDetails').then(module => ({ default: module.FfiecActionDetailsNew }))
);
const FfiecActionAccepted = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/ActionDetailsAccepted').then(module => ({ default: module.FfiecActionAccepted }))
);
const FfiecActionCancelled = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/ActionCancelled').then(module => ({ default: module.FfiecActionCancelled }))
);
const FfiecPoaDash = React.lazy(() =>
  import('../pages/Compliance/FFIEC/POA/FfiecPoaDash').then(module => ({ default: module.FfiecPoaDash }))
);
const TermsAndConditions = React.lazy(() =>
  import('../assets/components/Global/TermsAndConditions/TermsAndConditions.jsx').then(module => ({ default: module.TermsAndConditions }))
);
const PrivacyPolicy = React.lazy(() =>
  import('../assets/components/Global/PrivacyPolicy/PrivacyPolicy.jsx').then(module => ({ default: module.PrivacyPolicy }))
);
const UserDashboard = React.lazy(() =>
  import('../pages/User-Dashboard/UserDashboard').then(module => ({ default: module.UserDashboard }))
);
const CISDashboard = React.lazy(() =>
  import('../pages/Compliance/CIS/CIS/CISDashboard').then(module => ({ default: module.CISDashboard }))
);
const MSPDashboard = React.lazy(() =>
  import('../pages/MSPDashboard/MSPDashboard').then(module => ({ default: module.MSPDashboard }))
);
const ReportSAQ = React.lazy(() =>
  import('../pages/Reports/Controls/ReportSAQ').then(module => ({ default: module.ReportSAQ }))
);
const ReportRisk = React.lazy(() =>
  import('../pages/Reports/Risks/ReportRisk').then(module => ({ default: module.ReportRisk }))
);
const ReportActions = React.lazy(() =>
  import('../pages/Reports/PoA/ReportActions').then(module => ({ default: module.ReportActions }))
);
const ReportCyberHealth = React.lazy(() =>
  import('../pages/Reports/CyberHealth/ReportCyberHealth').then(module => ({ default: module.ReportCyberHealth }))
);
const FinraHome = React.lazy(() =>
  import('../pages/Compliance/FINRA/Home').then(module => ({ default: module.FinraHome }))
);
const FinraCyberMaturity = React.lazy(() =>
  import('../pages/Compliance/FINRA/CyberMaturity').then(module => ({ default: module.FinraCyberMaturity }))
);
const FinraCyberCore = React.lazy(() =>
  import('../pages/Compliance/FINRA/Cyber-Core').then(module => ({ default: module.FinraCyberCore }))
);
const FINRASAQRisks = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risks').then(module => ({ default: module.FINRASAQRisks }))
);
const FINRAHeatmap = React.lazy(() =>
  import('../pages/Compliance/FINRA/Heatmap').then(module => ({ default: module.FINRAHeatmap }))
);
const FINRAReportSAQ = React.lazy(() =>
  import('../pages/Compliance/FINRA/Reports/Controls/ReportSAQ').then(module => ({ default: module.FINRAReportSAQ }))
);
const FINRAReportRisk = React.lazy(() =>
  import('../pages/Compliance/FINRA/Reports/Risks/ReportRisk').then(module => ({ default: module.FINRAReportRisk }))
);
const FINRADashboard = React.lazy(() =>
  import('../pages/Compliance/FINRA/Dashboard/FINRADashboard').then(module => ({ default: module.FINRADashboard }))
);

const FINRAMitigateHome = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/Actions').then(module => ({ default: module.FINRAMitigateHome }))
);
const FINRAMitigateActionNewPage = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/ActionNew').then(module => ({ default: module.FINRAMitigateActionNewPage }))
);
const FINRAMitigateActionPage = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/ActionDetails').then(module => ({ default: module.FINRAMitigateActionPage }))
);
const FINRAMitigateActionPageAccepted = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/ActionDetailsAccepted').then(module => ({ default: module.FINRAMitigateActionPageAccepted }))
);
const FINRAMitigateActionPageCancelled = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/ActionCancelled').then(module => ({ default: module.FINRAMitigateActionPageCancelled }))
);
const FINRAMitigateDash2 = React.lazy(() =>
  import('../pages/Compliance/FINRA/Risk-Mitigate/MitigateDash2').then(module => ({ default: module.FINRAMitigateDash2 }))
);
const FINRAReportActions = React.lazy(() =>
  import('../pages/Compliance/FINRA/Reports/PoA/ReportActions').then(module => ({ default: module.FINRAReportActions }))
);
const AddMSPClient = React.lazy(() =>
  import('../pages/AddMSPClient/AddMSPClient').then(module => ({ default: module.AddMSPClient }))
);


const match = matchPath("/", {
    path: window.location.pathname,
    exact: true,
    strict: false,
})


//Resolved conflicts of indentation and new routes of ffiec
// Resolved by Rutuja on 15 DEC 2020
function getBrowserVersion() {
    let detectIEregexp
    if (navigator.userAgent.indexOf("MSIE") !== -1) {
        detectIEregexp = /MSIE (\d+\.\d+);/ //test for MSIE x.x
    } else {
        detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/ //test for rv:x.x or rv x.x where Trident string exists
    } // if no "MSIE" string in userAgent

    if (detectIEregexp.test(navigator.userAgent)) {
        //if some form of IE
        let ieversion = RegExp.$1 // capture x.x portion and store as a number
        // if (ieversion>=11 && ieversion<12)
        return ieversion
        // else if (ieversion>=9 && ieversion<10)
        // return (ieversion);
    } else {
        return "Other"
    }
}

function isVendorLoginLink() {
    let array = (window.location.pathname).split('/');
    let isVendorLoginLink = false
    if (array.includes('vendor') && array.includes('client')) {
        isVendorLoginLink = true;
    }
    return isVendorLoginLink;
}
let browserResult = getBrowserVersion()
let vendorLoginLink = isVendorLoginLink()

export const Unauthenticated = () => {
    /*
      Task: T282 - Error page
      1.No NoInternet
      2.Browser not unsupport
      Developer: Manohar Sule
      Date: 31/08/2020
    */

    let [isOnline, setIsOnline] = React.useState(true)
    let [userInfo, setUserInfo] = React.useState('')
    let [isMSPMultifactorAuth, setIsMSPMultifactorAuth] = React.useState(false)

    useEffect(() => {
        window.scroll(0, 0)
        let token = getItem('token');
    }, [])

    useEffect(() => {
      let userInfo = getItem('userInfo');
      // setUserInfo(userInfo)
      if (window.location.pathname === '/home' || window.location.pathname === '/vendors') {
        setIsMSPMultifactorAuth(false)
      }
      if (window.location.pathname === '/mspAdmin/profile/2fa') {
        if (userInfo && userInfo.userType === "client" && userInfo.isMSPUser) {
          if (userInfo.isMSPUserMFAActivated) {
            setIsMSPMultifactorAuth(true)
          } else {
            setIsMSPMultifactorAuth(false)
          }
          
        }
        if (userInfo && userInfo.userType === "MSP" && userInfo.isMSPUser) {
  
          if (userInfo.isMSPUserMFAActivated) {
            setIsMSPMultifactorAuth(true)
          } else {
            setIsMSPMultifactorAuth(false)
          }
          
        }
      } else {
        if (userInfo && (userInfo.userType === "MSP" || userInfo.userType === "client") && userInfo.isMSPUser) {
  
          if (userInfo.isMSPUserMFAActivated) {
            setIsMSPMultifactorAuth(true)
          } else {
            setIsMSPMultifactorAuth(false)
          }
          
        }
      }
      // else if(window.location.pathname !== "/") {
      //   if (userInfo.isMSPUserMFAActivated) {
      //     setIsMSPMultifactorAuth(true)
      //   } else {
      //     setIsMSPMultifactorAuth(false)
      //   }
      // }
      
  }, [window.location.pathname])

    useEffect(() => {
        let isOnline = window.navigator.onLine
        setIsOnline(isOnline)
        window.addEventListener("offline", () => {
            setIsOnline(false)
            return
        })
        window.addEventListener("online", () => {
            setIsOnline(true)
            // window.location.reload();
        })
    }, [])

    const noSidebarPaths = ["/", "/forgot-password", "/sign-up", "/invite", "/set-new-password", "/set-password", "/vendorAssess", "/vendorAssess/timeline"]

    return (
        <>
            <ThemeProvider theme={TDTheme}>
                {!noSidebarPaths.includes(window.location.pathname) && !vendorLoginLink && browserResult === "Other" && isOnline && (
                    <Sidebar />
                )}
                {browserResult === "Other" && isOnline && !isMSPMultifactorAuth && (
                    <ContentWrapper>

                        <GlobalsStyle />
                        <Suspense fallback={<div></div>}>

                        <Switch>
                            <Route exact path="/" component={Login} />

                            <Route exact path="/home" component={Home} />

                            <Route path="/vendor/:vendorID?/client/:clientID?" component={VendorLogin} />

                            <Route exact path="/sign-up" component={SignUp} />

                            <Route exact path="/forgot-password" component={ForgotPassword} />

                            <Route exact path="/set-new-password/:token?" component={SetNewPassword} />
                            <Route exact path="/set-password/:token?" component={SetPassword} />

                            <Route
                                exact
                                path="/quick-evaluation-questionnaire"
                                component={QuickEvaluationQuestionnaire}
                            />

                            <Route exact path="/quick-evaluation-results" component={QuickEvaluationResults} />

                            <Route exact path="/vendor-risk-evaluation" component={VendorIRQ} />


                            {/* <Route exact path="/home" component={Home} /> */}
                            <Route exact path="/invite" component={Invite} />

                            {/*  EVALUATE  ////////////////////////////////////  */}
                            <Route path="/evaluate/report" component={IRQReport} />

                            <Route path="/evaluate" component={IRQ} />

                            {/*  CYBER INSURANCE EVALUATE  ////////////////////////////////////  */}
                            <Route path="/cyber-insurance-evaluate/report" component={CIIRQReport} />

                            <Route  path="/cyber-insurance-evaluate" component={CIIRQ} />

                            {/*  ASSESS  //////////////////////////////////////  */}
                            <Route exact path="/assess/risks" component={SAQRisks} />

                            <Route exact path="/assess/threats" component={SAQThreats} />

                            <Route exact path="/assess/heatmap" component={Heatmap} />

                            <Route path="/assess" component={SAQ} />

                            {/*  VENDOR ASSESS  //////////////////////////////////////  */}
                            <Route path="/vendorAssess" component={VendorSAQ} />

                            {/* MITIGATE ///////////////////////////////////// */}

                            <Route path="/mitigate/actions/:id/new" component={MitigateActionNewPage} />

                            <Route path="/mitigate/actions/cancelled/:id" component={MitigateActionPageCancelled} />

                            <Route path="/mitigate/actions/accepted/:id" component={MitigateActionPageAccepted} />
                            <Route path="/mitigate/actions/notPlanned/:id" component={MitigateActionPageNotPlanned} />

                            <Route path="/mitigate/actions/:id" component={MitigateActionPage} />

                            <Route path="/mitigate/actions" component={MitigateHome} />

                            <Route path="/mitigate/empty" component={MitigateDashEmpty} />

                            <Route component={MitigateDash2} path="/mitigate/dashboard" />

                            {/*  Vendors  =====================================  */}
                            <Route exact path="/vendors" component={VendorHome} />

                            <Route component={VendorProfile} path="/vendors/:id" />

                            {/*  ADMIN  ///////////////////////////////////////  */}
                            <Route exact path="/users" component={Users} />

                            <Route path="/admin/org-profile" component={OrgProfile} />

                            <Route path="/admin/profile" component={Profile} />
                            <Route exact path="/admin/users" component={Users} />

                            <Route path="/mspAdmin/org-profile" component={OrgProfile} />

                            <Route path="/mspAdmin/profile" component={Profile} />
                            <Route exact path="/mspAdmin/users" component={Users} />

                            <Route exact path="/coming-soon" component={ComingSoon} />
                            <Route exact path="/unsupported-browser" component={UnsupportedBrowser} />
                            <Route exact path="/unknown-error" component={UnknownError} />
                            <Route exact path="/maintenance" component={Maintenance} />

                            {/* COMPLIANCE  //////////////////////////////  */}

                            {/* COMPLIANCE > FFIEC  ======================  */}

                            <Route path="/compliance/ffiec/poa/actions/:id/new" component={FfiecActionNewPage} />

                          {/*  <Route path="/compliance/ffiec/poa/:id" component={FfiecActionDetails} /> */}

                            <Route path="/compliance/ffiec/poa/actions/:id" component={FfiecActionDetailsNew} />

                            <Route path="/compliance/ffiec/poa/actions-accepted/:id" component={FfiecActionAccepted} />

                            <Route path="/compliance/ffiec/poa/actions-cancelled/:id" component={FfiecActionCancelled} />

                            <Route exact path="/compliance/ffiec/poa" component={FFIECActions} />

                            <Route exact path="/compliance/ffiec/cyber-maturity/data-metrics" component={FfiecDataMetrics} />

                            <Route exact path="/compliance/ffiec/cyber-maturity/report" component={FfiecMaturityReport} />

                            <Route exact path="/compliance/ffiec/cyber-maturity" component={FFiecCyberMaturity} />

                            <Route path="/compliance/ffiec/irq/report" component={FfiecIRQReport} />

                            <Route path="/compliance/ffiec/irq" component={FfiecIRQ} />

                            <Route exact path="/compliance/ffiec" component={FfiecHome} />

                            <Route exact path="/compliance/ffiec/poa-dash" component={FfiecPoaDash} />

                            <Route exact path="/termsAndConditions" component={TermsAndConditions} />
                            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />

                            <Route exact path="/currentPosture/Dashboard" component={UserDashboard} />

                            <Route exact path="/compliance/cis/dashboard" component={CISDashboard} />

                            {/*  MSPDashboard  =====================================  */}
                            <Route exact path="/MSPDashboard" component={MSPDashboard} />
                            <Route exact path="/mspAdmin/termsAndConditions" component={TermsAndConditions} />
                            <Route exact path="/mspAdmin/privacyPolicy" component={PrivacyPolicy} />

                            <Route exact path="/mspAdmin/vendors" component={VendorHome} />

                            <Route component={VendorProfile} path="/mspAdmin/vendors/:id" />

                            {/*  REPORTS  =====================================  */}
                            <Route path="/report/controls"  component={ReportSAQ} />
                            <Route exact path="/report/risks" component={ReportRisk} />
                            <Route exact path="/report/mitigate" component={ReportActions} />

                            <Route exact path="/report/cyberHealth" component={ReportCyberHealth} />

                            <Route exact path="/compliance/finra/cyber-maturity/report" component={FfiecMaturityReport} />

                            <Route exact path="/compliance/finra/cyber-maturity" component={FinraCyberMaturity} />
                            <Route exact path="/compliance/finra/core" component={FinraCyberCore} />

                            <Route exact path="/compliance/finra" component={FinraHome} />
                            <Route exact path="/compliance/finra/assess/risks" component={FINRASAQRisks} />
                            <Route exact path="/compliance/finra/assess/heatmap" component={FINRAHeatmap} />
                            <Route exact path="/compliance/finra/mitigate/actions" component={FINRAMitigateHome} />
                            <Route path="/compliance/finra/mitigate/actions/:id/new" component={FINRAMitigateActionNewPage} />
                            <Route path="/compliance/finra/mitigate/actions/cancelled/:id" component={FINRAMitigateActionPageCancelled} />

                            <Route path="/compliance/finra/mitigate/actions/accepted/:id" component={FINRAMitigateActionPageAccepted} />

                            <Route path="/compliance/finra/mitigate/actions/:id" component={FINRAMitigateActionPage} />

                            <Route path="/compliance/finra/report/controls"  component={FINRAReportSAQ} />
                            <Route exact path="/compliance/finra/report/risks" component={FINRAReportRisk} />

                            <Route exact path="/compliance/finra/dashboard" component={FINRADashboard} />
                            <Route component={FINRAMitigateDash2} path="/compliance/finra/mitigate/dashboard" />

                            <Route exact path="/compliance/finra/report/mitigate" component={FINRAReportActions} />

                            {/*  FINRA EVALUATE  ////////////////////////////////////  */}
                            <Route path="/compliance/finra/evaluate/report" component={IRQReport} />

                            <Route path="/compliance/finra/evaluate" component={IRQ} />

                            <Route path="/mspAdmin/add-client" component={AddMSPClient} />
                            {/* <Route path="/mspAdmin/contact-client" component={AddMSPClient} /> */}







                            <Route component={Error404} />


                        </Switch>
                        </Suspense>
                    </ContentWrapper>

                )}
                {browserResult !== "Other" && !isOnline && <UnsupportedBrowser />}

                {browserResult === "Other" && isOnline && isMSPMultifactorAuth && (
                    <ContentWrapper>

                        <GlobalsStyle />
                        <Suspense fallback={<div></div>}>

                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route path="/admin/profile" component={Profile} />
                            <Route path="/mspAdmin/profile" component={Profile} />
                            <Route component={MFAErrorPage} />
                        </Switch>
                        </Suspense>
                        </ContentWrapper>
                )}
            </ThemeProvider>
        </>
    )
}
