import { createGlobalStyle } from "styled-components"

import { defaultColours, defaultColours as baseColors } from "fictoan-react"
import { mainColors } from "./Theme"

//Resolved conflicts to keep ui-main styling (added colors for minimal, Maverick, high and critical risk cards )
//Resolved by Rutuja on 15 DEC 2020
export const GlobalsStyle = createGlobalStyle`

    body { font-size: 0.9375em !important; font-family: "IBMPlexSansRegular", sans-serif !important; line-height: 1.64 !important }

    article {
        min-height     : 100vh;
        padding-bottom : 4vh;
    }
    .text-violet {
        color: hsl(204deg 61% 40%);
    }
    .bg-violet {
        background-color: #2874a6;
    }
    .bg-violet-10 {
        background-color: hsl(204deg 61% 34% / 10%);
    }
    .bg-violet-70 {
        background-color: hsl(204deg 61% 34% / 70%);
    }
    .bg-violet-20 {
        background-color: hsl(204 61% 40% / 0.20);
    }
    .border-violet-20 {
        border: 2px solid hsl(204deg 61% 40% / 47%);
    }
    .text-violet-90 {
        color: #2874a6c7;
    }
    .border-blue-30 {
        border: 2px solid hsl(204deg 61% 40%);
    }
    .bg-blue-10 {
        background-color: hsl(204deg 32% 60% / 35%);
    }
    .bg-maverick {
        background-color: #b61417
    }

    a { width : unset; }

    p { font-family: "IBMPlexSansRegular", sans-serif !important; margin : 0; }

    select:: after {
        color: hsl(204deg 61% 40%) !important;
    }

    .sidebar-sublink { font-size: 0.92em; }

    .vertically-centre-items,
    .vertically-center-items {
        display: flex;
    }

    .vertically-centre-items *,
    .vertically-center-items * {
        align-self : center;
    }

    .push-to-ends {
        display         : flex;
        justify-content : space-between;
        &:hover {
            .move-step-icon {
                visibility : visible;
            }
        }
    }

    .uppercase-this { text-transform : uppercase; }

    body::-webkit-scrollbar { width: 0 !important }
    body { overflow: -moz-scrollbars-none; }
    body { -ms-overflow-style: none; }

    *:focus { outline: 0 !important; }

    a {
      -webkit-text-decoration: none !important;
      text-decoration: none !important;
    }

    input:active,
    input:focus { border : 2px solid hsl(204deg 61% 40%) !important }

    button { background-color : white; }

    strong,
    .weight-600 {
        font-family: "IBMPlexSansBold", sans-serif !important;
    }

    input[type="date"] { padding : 11px !important;
        border: 1px solid hsl( 208,13%,80%) !important;
        background-color: hsl( 0,0%,100%) !important;
        background-image: none !important;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
    }

    input[type="date"]:before {
        color: transparent;
        background: none;
        display: block;
        font-size: 18px;
        font-family: 'FontAwesome';
        content: '\f073';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 12px;
        right: 12px;
        color: #999;
    }

    .input[type="date"]:invalid:not(:focus):not(:placeholder-shown) {
        border: 1px solid hsl( 208,13%,80%) !important;
        background-color: hsl( 0,0%,100%) !important;
        background-image: none !important;
    }

    .select::after {
        position: absolute;
        display: block;
        content: "⌃";
        top: 2px !important;
        right: 12px;
        font-size: 24px;
        color: rgb(0, 114, 245);
        line-height: 1.2;
        border-color: rgb(0, 114, 245);
        z-index: 500;
        pointer-events: none;
        transform: rotate(180deg);
    }

    #header { box-shadow : 0 2px 16px -2px rgba(0, 0, 0, 0.2); }

    @keyframes fadeInDown {
        0%    {opacity: 0; transform: translateY( -10px); }
        100%  {opacity: 1; transform: translateY( 0px); }
    }

    .no-scrollbar {
        scrollbar-width    : none;
        overflow           : auto;
        overflow           : -moz-scrollbars-none;
        -ms-overflow-style : none;
    }
    .no-scrollbar::-webkit-scrollbar { display : none; }
    .no-scrollbar::-moz-scrollbar { display : none; }

    #app-sidebar hr { background-color: ${baseColors.slate10} !important; }
    #app-sidebar .header-logo { width : 64%; }

    #app-sidebar summary p {
        font-weight : 600;
        font-family : "IBMPlexSansBold", sans-serif !important;
    }

    details.animated[open] summary ~ *  {
        animation-name     : fadeInDown;
        animation-duration : 0.4s;
    }

    .nivo-wrapper-large { height : 200px; }

    /* SIDEBAR  ///////////////////////////////////////////////////////////  */
    summary .feather {
        stroke : ${baseColors.slate60};

        &:hover { stroke: ${mainColors.hue}; }
    }

    #app-sidebar.collapsed summary:after { display: none !important; }

    #app-sidebar summary { font-family: "IBMPlexSansBold", sans-serif !important; }

    #app-sidebar #linkset-wrapper {
        position   : relative;
        transition : all 0.4s ease-in-out;

        &.linkset-1-active { transform: translateX(0); }
        &.linkset-2-active { transform: translateX(-240px); }
        &.linkset-3-active { transform: translateX(-240px); }
        &.linkset-4-active { transform: translateX(-0); }
        &.linkset-5-active { transform: translateX(-240px); }
        &.linkset-6-active { transform: translateX(-0); }
        &.linkset-7-active { transform: translateX(-0); }


        .linkset {
            position : absolute;
            width    : 240px;
            top      : 0;
        }

        #linkset-2 { transform : translateX(240px); }
        #linkset-3 { transform : translateX(240px); }
        #linkset-5 { transform : translateX(240px); }
    }

    summary {
        position : relative;
        cursor   : pointer;
    }

    summary::after {
        display      : inline-block;
        position     : absolute;
        width        : 8px;
        height       : 8px;
        top          : 8px;
        right        : 18px;
        content      : "";
        border-style : solid;
        border-width : 0 2px 2px 0;
        transform    : rotate(45deg);
        color        : hsl( 208,13%,80%);
        transition   : all 0.2s;
        cursor       : pointer;
    }

    .sticky-header {
        position         : sticky;
        top              : 0;
        width            : 110%;
        padding          : 16px 0;
        z-index          : 5000000;
        background-color : ${baseColors.slate10};
    }

    /* TABS  //////////////////////////////////////////////////////////////  */
    .tabs-wrapper nav {
        position         : absolute;
        top              : 0;
        left             : 0;
        width            : 240px;
        height           : 100%;
        background-color : rgba(241,243,244,0.16);
        display          : flex;
        flex-direction   : column;
        padding          : 24px 32px;
        border-right     : 1px solid ${baseColors.slate20};

        .nav-wrapper {
            position : sticky;
            top      : 16px;
        }

        a        { color : ${baseColors.slate80} }
        a.active {
            color       : ${mainColors.hue};
            font-family : "IBMPlexSansBold", sans-serif !important;
        }

        a.active::after {
            position    : absolute;
            display     : inline-block;
            content     : "\\2192";
            margin-left : 8px;
        }

        progress[value] {
        height : 4px;
            &.muted {
                &::-webkit-progress-value { background: ${baseColors.slate70}; }
                &::-moz-progress-bar { background: ${baseColors.slate70}; }
            }
        }
    }

    .tabs-wrapper main {
        margin-left : 240px;
        padding     : 40px;
    }

    .hero-img {
        width  : 300px;
        margin : 0 auto;
    }

    .check-set input[type="checkbox"] + label { margin-bottom : 12px; }

    #vendor-irq { position : relative; }
    #vendor-irq::before {
        display          : block;
        position         : absolute;
        z-index          : -1;
        content          : "";
        width            : 100%;
        height           : 40vh;
        top              : 0;
        left             : 0;
        background-color : ${baseColors.violet20};
    }

    .td-logo {
        width  : 200px;
        margin : 0 auto;
    }

    .step-card {
        min-height     : 320px;
        display        : flex;
        flex-direction : column;

        a { margin-top : auto; }
    }

    /* REPORT  ////////////////////////////////////////////////////////////  */
    .category-column { padding: 16px; }

    div[class*="risk-card-"] {
        margin-bottom : 24px;

        &::before {
            position      : absolute;
            content       : "";
            left          : 8px;
            top           : 8px;
            height        : calc(100% - 16px);
            width         : 8px;
            border-radius : 4px;
        }
    }

    .overview-card { border: 1px solid ${baseColors.slate20}; }

    div[class*="risk-card-"]:last-of-type { margin-bottom : 0; }

    .risk-card-nil::before              { background-color : #ccc; }
    .risk-card-negligible::before       { background-color : #3492fe; }
    .risk-card-na::before               { background-color : #ccc; }
    // .risk-card-open::before             { background-color : #36a168; }
    .risk-card-open::before             { background-color : #ff5657; }
    .risk-card-low::before              { background-color : ${baseColors.green70}; }
    .risk-card-averse::before           { background-color : ${baseColors.green}; }
    // .risk-card-cautious::before         { background-color : ${baseColors.yellow}; }
    .risk-card-cautious::before         { background-color : #ff8833; }
    .risk-card-insignificant::before    { background-color : ${baseColors.green70}; }
    .risk-card-significant::before      { background-color : #ff8833; }
    .risk-card-minimal::before          { background-color : ${baseColors.green70}; }
    .risk-card-maverick::before          { background-color : #b61417; }
    .risk-card-high::before             { background-color : #ff5657; }
    .risk-card-critical::before         { background-color : #b61417; }
    .risk-card-completed::before         { background-color : hsl( 148,50%,42%); }
    .risk-card-mostlyDone::before         { background-color : hsl( 78,54%,52%);    }

    .icon-holder {
        display          : grid;
        place-items      : center;
        width            : 32px;
        height           : 32px;
        border-radius    : 50%;
        background-color : ${baseColors.slate10};
        overflow         : hidden;

        svg {
            stroke       : ${baseColors.green70};
            stroke-width : 2;
        }
    }

    .data-point-card {
        height   : 300px;
        overflow : hidden;

        p {
            position  : relative;
            z-index   : 5;
            font-size : 200%;
        }

        svg { fill : #dc5757; }

        .image-container {
            position : absolute;
            bottom   : -32%;
            right    : 10%;
            width    : 50%;
            z-index  : 1;
        }
    }

    #saq main hr {
        margin  : 4vh 0;
        display : inline-table;
    }

    strong,
    .weight-600 { font-family : "IBMPlexSansBold", sans-serif; }

    .with-progress.tabs-wrapper nav a.active::after { display: none; }

    .with-progress.tabs-wrapper nav a.active p:first-child { color: ${mainColors.hue}; }

    .help-underline {
        display        : inline-block;
        cursor         : help;
        border-bottom  : 1px dotted ${baseColors.slate};
    }

    //hr { background-color: ${baseColors.slate30} !important}

    .help-underline { border-bottom: 1px dotted ${baseColors.slate}; padding-bottom: 1px; display: inline-block; cursor: help; }
    .not-allowed { cursor: not-allowed !important}
    .cursor-default { cursor: default !important}

    .pointer { cursor: pointer !important}

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .is-locked {
        position       : relative;
        pointer-events : none;

        &::before {
            display          : flex;
            position         : absolute;
            left             : 0;
            top              : 0;
            width            : 100%;
            height           : 100%;
            content          : "";
            background-color : rgba(0, 0, 0, 0.56);
            z-index          : 5000;
            backdrop-filter  : blur(2px);
            border-radius    : 4px;
        }

        &::after {
            display     : flex;
            position    : absolute;
            padding     : 0 24px;
            content     : "Please upgrade your TitanDef subscription to access this feature";
            left        : 0;
            top         : 5%;
            color       : #fff;
            z-index     : 5500;
            text-align  : center;
            font-weight : 600;
        }
    }


/* The Modal (background) */
.modal {
  display: inline; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  padding: 10px 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 40vw;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-close {
    cursor : pointer;
  color: #373737;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover {
    color: #000
  }
}

.modal-header {
  padding: 2px 16px;
  background-color: #e2e9ed;
  color: #8d8d8d;
  height: 50px;
}

.modal-header-title {
  padding-top: 8px;
  color: #373737;
  font-size: 1.16em;
}

.modal-body {
  padding: 10px 16px;
  height: 50vh;
  overflow-y: auto;
}

.modal-body .container div div {
  height: 100% !important;
  // min-height: 200px !important
}

.modal-footer {
  padding: 11px 16px;
  background-color: #e2e9ed;
  height: 50px;
}
.answer-wrapper {
    label { line-height : 1.6; }

    label::before,
    label::after { margin-top : 4px; }
}
.rt-tr-group {
  &:hover {
    background-color: hsl( 40,95%,92%) !important;
    color: hsl( 0,0%,0%);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
  }
}
.rt-th {
  background-color: #22618c99;
  color: #fff;
  font-weight: 600;
  font-family: IBMPlexSansBold, sans-serif;
  min-height: 42px;
  white-space: normal !important;
}
.rt-td {
  white-space: normal !important;
  text-align: center;
}
.ReactTable .rt-tbody {
  min-height: 70px;
}
.pdfview {
  height: 100%;
  overflow-x: auto;
}
.jALfqH{
  z-index: 999 !important;
}
.not-applicable-type {
    background-color: #ececec;
    border: 1px solid hsl( 210,12%,70%);
}
.not-applicable-type::before {
    background-color: hsl( 210,12%,70%)
}
.no-type {
    background-color: #fbd0d0;
    border: 1px solid hsl( 0,84%,60%);
}
.no-type::before {
    background-color: hsl( 0,84%,60%)
}
.yes-cc-type {
    background-color: #e6f7c1;
    border: 1px solid hsl(78deg 54% 52%);
}
.yes-cc-type::before {
    background-color: hsl(78deg 54% 52%)
}
.yes-type {
    background-color: #ccf5df;
    border: 1px solid hsl(148deg 50% 42%);
}
.yes-type::before {
    background-color: hsl(148deg 50% 42%)
}
.bkKoKx {
    bottom: 0 !important;
    height: auto;
    overflow: hidden;
    top: auto !important;
}
.eDJvQP {
    bottom: 0 !important;
    height: auto;
    overflow: hidden;
    top: auto !important;
}
.bottom {
    bottom: 0 !important;
    height: auto;
    overflow: hidden;
    top: auto !important;
}
.move-step-icon {
    visibility : hidden;
}
.numberFormatDiv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}
.numberFormatDivInput:active, .numberFormatDivInput:focus {
    background-color: hsl( 0,0%,100%);
    border: 2px solid hsl( 212,100%,48%);
    padding: 11px;
}
.numberFormatDiv .numberFormatDivInput:focus ~ label {
    font-weight: bold;
}
.numberFormatDiv:not(:last-child) {
    margin-bottom: 32px;
}
.numberFormatDivInput {
    position: relative;
    width: 100%;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-family: sans-serif;
    color: hsl( 0,0%,34%);
    background-color: hsl( 0,0%,100%);
    border: 1px solid hsl( 208,13%,80%);
    border-radius: 4px;
    padding: 12px;
    line-height: 1;
}
.numberFormatDiv > label {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-bottom: 4px;
    color: hsl( 0,0%,34%);
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.numberFormatDiv input:required ~ label::before {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: auto !important;
    right: -12px;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: hsl( 0,84%,60%);
}

input:required ~ label::before {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: auto !important;
    right: -12px;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: hsl( 0,84%,60%);
}

.selectDiv label::before {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: auto !important;
    right: -12px;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: hsl( 0,84%,60%);
}
.required > label::before {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: auto !important;
    right: -12px;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: hsl( 0,84%,60%);
}
input[type="date"]:required ~ label::before {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: auto !important;
    right: -12px !important;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: hsl( 0,84%,60%);
}
.irq-report-img {
    margin: 0 auto;
    padding: 6px;
}
.dashboardTileMergin {
    margin-bottom: 24px;
}
.lastNotify {
    position: fixed;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    height: auto;
    z-index: 9000000;
    padding: 4vh 2vw;
    overflow-y: hidden;
}

.lastNotify bottom {
    bottom: 0;
}

.lastNotifyDiv {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    padding: 16px;
    background-color: hsl( 200,11%,95%);
    border-radius: 4px;
    border: 1px solid hsla(0,0%,0%,0.04);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    margin: 8px 0;
    box-shadow: 0 2px 0.2px rgba(0,0,0,0.016), 0 4px 0.8px rgba(0,0,0,0.024), 0 6px 2.4px rgba(0,0,0,0.032), 0 8px 4.8px rgba(0,0,0,0.040), 0 16px 8px rgba(0,0,0,0.056), 0 24px 16px rgba(0,0,0,0.064);
}

.lastNotifyDiv.warning {
    background-color: #fef3dc;
    border: 1px solid hsl( 40,97%,61%);
}

.lastNotifyDiv.error {
    background-color: #fdecec;
    border: 1px solid hsl( 0,84%,64%);
}

.lastNotifyDiv::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 100%;
    content: "";
    border-radius: 2px 0 0 2px;
}
.lastNotifyDiv.warning::before {
    background-color: hsl( 40,97%,61%);
}

.lastNotifyDiv.error::before {
    background-color: hsl( 0,84%,64%);
}

.lastNotifyDiv .notification-content {
    color: hsl( 0,0%,34%);
    font-size: 14px;
    line-height: 1.1;
    word-break: break-word;
    margin: 0 8px;
}

.activeRow {
    background: #dad7d2;
    color: #000;
}
.descriptionError {
    color: hsl( 0,84%,60%);
    font-size: 0.8rem;
    position: relative;
    bottom: 1.7rem;
}

#budgetProgress p:last-of-type {
    display: none;
}

@media screen and (min-width: 1920px) {
    .dashboardTileMergin {
        margin-bottom: 0;
    }
}
.rc-tooltip {
    font-size: 0.7rem !important;
}
.jmhgJ {
    max-width: 100vw !important;
}
.poaInput + label {
    color: hsl( 210,12%,55%);
    font-size: 90%;
}
select {
    color: hsl( 0,0%,34%);
}
input:disabled {
    background-color: #e1dede !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed !important;
}
select:disabled {
    background-color: #e1dede !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}
textarea:disabled {
    background-color: #e1dede !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}
aside {
    max-width: 100vw !important;
}
.requiredStar {
    position: absolute;
    top: -4px;
    bottom: 0px;
    right: -12px;
    margin: auto;
    content: " *";
    font-size: 120%;
    color: rgb(239, 67, 67);
    left: auto !important;
}

`
