import { lighten } from "polished"
import { defaultColours } from "fictoan-react"

export const mainColors = {
    hue: "#2874a6",
    shade: defaultColours.black,
}

//Resolved conflicts to keep defaultColours
//Resolved by Rutuja on 15 DEC 2020
export const TDTheme = {
    //  BUTTON  ///////////////////////////////////////////////////////////////
    button: {
        fontFamily: "IBMPlexSansBold",
        primary: {
            default: {
                bg: mainColors.hue,
                border: mainColors.hue,
                text: defaultColours.white,
                borderRadius: "4px",
            },
            onHover: {
                bg: mainColors.hue,
                border: mainColors.hue,
                text: defaultColours.white,
            },
            isActive: {
                bg: mainColors.hue,
                border: mainColors.hue,
                text: defaultColours.white,
            },
            isLoading: {
                bg: mainColors.hue,
                spinnerBorder: defaultColours.black,
            },
        },
        secondary: {
            default: {
                bg: `${lighten(0.52, mainColors.hue)}`,
                border: mainColors.hue,
                text: mainColors.hue,
                borderRadius: "4px",
            },
            onHover: {
                bg: `${lighten(0.52, mainColors.hue)}`,
                border: mainColors.hue,
                text: mainColors.hue,
            },
            isActive: {
                bg: `${lighten(0.4, mainColors.hue)}`,
                border: mainColors.hue,
                text: mainColors.hue,
            },
            isLoading: {
                bg: defaultColours.white,
                spinnerBorder: defaultColours.black,
            },
        },
    },

    //  TEXT  /////////////////////////////////////////////////////////////////
    text: {
        font: {
            // sans : "GoodSans"
        },
        paras: {
            color: defaultColours.black,
            size: 1,
        },
        size: {
            default: 0.92,
            multiplier: 1.16,
        },
        headings: {
            font: "IBMPlexSansBold",
            lineHeight: 1.64,
            color: defaultColours.black,
            multiplier: 1.16,
        },
        links: {
            default: "#1f618d",
            onHover: defaultColours.violet90,
        },
        input: {
            onFocus: {
                border: "#1f618d",
            },
        },
    },

    //  SIDEBAR  //////////////////////////////////////////////////////////////
    sidebar: {
        isCollapsed: {
            label: {
                text: defaultColours.white,
                bg: mainColors.hue,
            },
        },

        header: {
            bg: defaultColours.white,
            borderBottom: defaultColours.slate10,
        },

        body: {
            bg: defaultColours.white,
        },

        icons: {
            stroked: {
                onHover: {
                    line: defaultColours.slate80,
                },
                isActive: {
                    line: mainColors.hue,
                },
            },
        },

        links: {
            default: {
                bg: defaultColours.white,
                text: mainColors.shade,
                scale: 100,
                weight: 400,
            },
            isSelected: {
                bg: defaultColours.white,
                border: mainColors.hue,
                text: mainColors.hue,
            },
        },
    },

    //  RULE  /////////////////////////////////////////////////////////////////
    hr : {
        primary : {
            bg     : defaultColours.slate60,
            height : "1px"
        },
        secondary : {
            bg     : defaultColours.slate40,
            height : "1px"
        },
        tertiary : {
            bg     : defaultColours.slate20,
            height : "1px"
        }
    },

    //  TABLE  ////////////////////////////////////////////////////////////////
    table: {
        bg: defaultColours.white,
        // text    : mainColors.shade,
        border: defaultColours.slate20,
        striped: {
            header: {
                bg: defaultColours.violet20,
            },
            cell: {
                bg: `${lighten(0.04, defaultColours.slate10)}`,
            },
        },
        onHover: {
            bg: defaultColours.amber20,
            text: mainColors.shade,
        },
    },

    //  PROGRESS BAR  /////////////////////////////////////////////////////////
    progressBar : {
        bg     : defaultColours.slate20,
        fill   : mainColors.hue,
        label  : mainColors.shade,
        value  : `${lighten(0.24, mainColors.shade)}`,
    }
}
